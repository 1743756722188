import React, { useState, useEffect } from "react";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Button,
  Box,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import PrefectureDialog from "./PrefectureDialog";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
} from "@mui/material";

const CourseSelection = ({userId}) => {
  const [selectedCourse, setSelectedCourse] = useState("");
  const [currentSelection, setCurrentSelection] = useState("");
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [prefecture, setPrefecture] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState("");
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(true);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const handleCourseUpdate = async () => {
    if (selectedCourse === "音源視聴 & グループ活動") {
      setConfirmationDialogOpen(true); // 確認ダイアログを開く
      return;
    }
    await updateCourseSelection(); // 通常の登録処理
  };

  // Firestore フィールド名
  const fieldName = "purpose2025FirstHalf";

  // 締切日時
  const deadline = new Date("2025-02-28T23:59:00");

  // Firestore からデータを取得
  useEffect(() => {
    const userDoc = doc(db, "users", userId);

    const unsubscribe = onSnapshot(userDoc, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        setPrefecture(data.prefecture || "");
        setCurrentSelection(data[fieldName] || ""); // Firestore の値を取得
        setSelectedCourse(data[fieldName] || ""); // `selectedCourse` も設定
      } else {
        setPrefecture("");
        setCurrentSelection("");
        setSelectedCourse("");
      }
      setLoading(false);
    });

    return () => unsubscribe(); // クリーンアップ
  }, []);

  // カウントダウンタイマー
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const diff = deadline - now;

      if (diff <= 0) {
        setTimeLeft("締切が過ぎました");
        setIsRegistrationOpen(false);
        clearInterval(interval);
      } else {
        const days = String(Math.floor(diff / (1000 * 60 * 60 * 24))).padStart(
          2,
          "0"
        );
        const hours = String(
          Math.floor((diff / (1000 * 60 * 60)) % 24)
        ).padStart(2, "0");
        const minutes = String(Math.floor((diff / (1000 * 60)) % 60)).padStart(
          2,
          "0"
        );
        const seconds = String(Math.floor((diff / 1000) % 60)).padStart(2, "0");
        setTimeLeft(`${days}日 ${hours}:${minutes}:${seconds}`);
      }
    }, 1000);

    return () => clearInterval(interval); // クリーンアップ
  }, [deadline]);

  // コース選択更新
  //   const handleCourseUpdate = async () => {
  //     try {
  //       const userDoc = doc(db, "users", auth.currentUser.uid);
  //       await updateDoc(userDoc, { [fieldName]: selectedCourse });
  //       setSnackbarMessage("コースが登録されました！");
  //     } catch (error) {
  //       console.error("Failed to update course selection:", error);
  //       setSnackbarMessage("コース登録に失敗しました。もう一度お試しください。");
  //     }
  //   };

  const updateCourseSelection = async () => {
    try {
      const userDoc = doc(db, "users", userId);
      await updateDoc(userDoc, { [fieldName]: selectedCourse });
      setSnackbarMessage("コースが登録されました！");
    } catch (error) {
      console.error("Failed to update course selection:", error);
      setSnackbarMessage("コース登録に失敗しました。もう一度お試しください。");
    }
  };

  // スナックバーを閉じる
  const handleSnackbarClose = () => {
    setSnackbarMessage("");
  };

  // PrefectureDialog を開く
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  // PrefectureDialog を閉じる
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%", // 親コンポーネント内に収まる
          margin: "0 auto",
          padding: 3,
          border: "1px solid #ccc",
          borderRadius: 2,
          boxShadow: 2,
          backgroundColor: "white",
          marginBottom: "12px",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
          コース選択
        </Typography>
        {loading ? (
          <Typography>読み込み中...</Typography>
        ) : prefecture === "" ? ( // 居住地が設定されていない場合
          <>
            <Alert severity="warning" sx={{ mb: 2 }}>
              先に居住地を設定してください。
            </Alert>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDialogOpen}
              fullWidth
              sx={{ mb: 2 }}
            >
              居住地を設定
            </Button>
            <PrefectureDialog
              open={dialogOpen}
              handleClose={handleDialogClose}
              onPrefectureUpdated={() =>
                setSnackbarMessage("居住地が設定されました！")
              }
            />
          </>
        ) : (
          <>
            <Typography
              variant="subtitle1"
              sx={{
                color: "red",
                fontWeight: "bold",
                mb: 2,
                textAlign: "center",
              }}
            >
              2025年2月28日23時59分00秒以降は、コース選択できなくなります。
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: "red",
                fontWeight: "bold",
                mb: 3,
                textAlign: "center",
              }}
            >
              上記時間を過ぎても未設定の場合は、
              <br />
              「帰宅部(音源視聴のみ)」を選択したとみなします。
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 2,
                textAlign: "center",
                fontWeight: "bold",
                color: isRegistrationOpen ? "green" : "red",
              }}
            >
              締切まで: {timeLeft}
            </Typography>
            {currentSelection && (
              <Alert severity="info" sx={{ mb: 2 }}>
                現在の設定: {currentSelection}
              </Alert>
            )}
            <FormControl component="fieldset" disabled={!isRegistrationOpen}>
              <RadioGroup
                value={selectedCourse}
                onChange={(e) => setSelectedCourse(e.target.value)}
              >
                <FormControlLabel
                  value="音源視聴のみ"
                  control={<Radio />}
                  label={
                    <>
                      基本コース：自宅で音源を聴く活動（帰宅部）
                      {currentSelection === "音源視聴のみ" && (
                        <Typography
                          component="span"
                          sx={{ color: "blue", fontWeight: "bold", ml: 1 }}
                        >
                          （現在の設定）
                        </Typography>
                      )}
                    </>
                  }
                />
                <FormControlLabel
                  value="音源視聴 & グループ活動"
                  control={<Radio />}
                  label={
                    <>
                      グループ活動：音源視聴＋グループ活動（アクティブ部）
                      {currentSelection === "音源視聴 & グループ活動" && (
                        <Typography
                          component="span"
                          sx={{ color: "red", fontWeight: "bold", ml: 1 }}
                        >
                          （現在の設定）
                        </Typography>
                      )}
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>

            {/* <FormControl component="fieldset" disabled={!isRegistrationOpen}>
              <RadioGroup
                value={selectedCourse}
                onChange={(e) => setSelectedCourse(e.target.value)}
              >
                <FormControlLabel
                  value="音源視聴のみ"
                  control={<Radio />}
                  label="基本コース：自宅で音源を聴く活動（帰宅部）"
                />
                <FormControlLabel
                  value="音源視聴 & グループ活動"
                  control={<Radio />}
                  label="グループ活動：音源視聴＋グループ活動（アクティブ部）"
                />
              </RadioGroup>
            </FormControl> */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleCourseUpdate}
              disabled={
                !selectedCourse ||
                selectedCourse === currentSelection ||
                !isRegistrationOpen
              }
              fullWidth
              sx={{ mt: 3 }}
            >
              登録
            </Button>
          </>
        )}
        <Snackbar
          open={!!snackbarMessage}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      </Box>

      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
      >
        <DialogTitle>重要なお知らせ</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            1.
            「グループ活動：音源視聴＋グループ活動（アクティブ部）」ご参加について
            <br />
            マルシェやフェスへの出展・企画参加、およびグループ活動への参加が必須となります。
            <br />
            時間的なコミットメントが可能な方のみお申し込みください。
            <br />
            <br />
            2. 禁止事項について
            <br />
            ビジネスに誘導する商品・サービス、宗教、ネットワークビジネス、投資商品等の勧誘・宣伝行為は禁止しております。
            <br />
            勧誘行為やコミュニティの秩序を乱す行為が発覚した場合は、強制退会となりますので、あらかじめご了承ください。
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxChecked}
                onChange={(e) => setCheckboxChecked(e.target.checked)}
                color="primary"
              />
            }
            label="上記内容を読み、理解しました。"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmationDialogOpen(false)}
            color="secondary"
          >
            閉じる
          </Button>
          <Button
            onClick={async () => {
              await updateCourseSelection(); // コース登録を実行
              setConfirmationDialogOpen(false); // ダイアログを閉じる
            }}
            color="primary"
            disabled={!checkboxChecked} // チェックボックスが未選択の場合はボタンを無効化
          >
            登録
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CourseSelection;
