import React, { useState } from "react";
import { doc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button, Snackbar, Tooltip } from "@mui/material";
// import LoaderName from "./LoaderName";
// import { PencilIcon } from "@heroicons/react/solid";
// import { useDocumentData } from "react-firebase-hooks/firestore";
// import ModalForm from "./ModalForm";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ProfileName = () => {
  //   const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleEdit = (e) => {
    setOpen(true);
    setForm(e);
  };

  const handleCopy = () => {
    if (auth.currentUser?.uid) {
      navigator.clipboard
        .writeText(auth.currentUser.uid)
        .then(() => {
          setSnackbarOpen(true);
        })
        .catch((error) => {
          console.error("コピーに失敗しました:", error);
        });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <div className="flex  mb-3 font-mono  sm:text-left  justify-center">
        {/* <ModalForm form={form} setOpen={setOpen} open={open} /> */}
        <div className="flex space-x-2">
          {/* UID表示とコピー要素 */}
          <div className="text-center">
            {/* <div className="text-sm text-gray-500">UID:</div> */}
            <div
              onClick={handleCopy}
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f5f5f5",
                padding: "8px 12px",
                borderRadius: "4px",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
                cursor: "pointer",
                fontSize: "14px",
                marginTop: "5px",
                fontWeight: "bold",
              }}
            >
              <ContentCopyIcon style={{ marginRight: "5px" }} />
              {auth.currentUser?.uid || "UIDがありません"}
            </div>
          </div>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message="コピー完了しました"
            action={
              <Button
                color="secondary"
                size="small"
                onClick={handleSnackbarClose}
              >
                閉じる
              </Button>
            }
          />

          {/* <div className=" font-bold text-3xl">{user?.lastName}</div>
          <div className="font-bold text-3xl">{user?.firstName}</div> */}
        </div>
      </div>
    </>
  );
};

export default ProfileName;
