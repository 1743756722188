import React, { useEffect, useState } from "react";
import { db, auth } from "../api/firebase";
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  getDoc,
  Timestamp,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ja";
import { useNavigate } from "react-router-dom";
import UnresolvedRequestsList from "./UnresolvedRequestsList";
import ResolvedRequestsList from "./ResolvedRequestsList";

dayjs.extend(relativeTime);
dayjs.locale("ja");


const ContactRequests = () => {
  const [requests, setRequests] = useState([]);
  const [users, setUsers] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [responses, setResponses] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingResponse, setEditingResponse] = useState(null);
  const [editingText, setEditingText] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdmin = async () => {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsAdmin(userData.isAdmin);
        if (!userData.isAdmin) {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    };

    checkAdmin();
  }, [navigate]);

  useEffect(() => {
    const q = query(
      collection(db, "support"),
      orderBy("createdAt", "desc"),
      limit(1000)
    );

    const unsubscribeRequests = onSnapshot(
      q,
      (snapshot) => {
        const requestsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRequests(requestsData);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching support requests: ", error);
      }
    );

    return () => {
      unsubscribeRequests();
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const toggleResolvedStatus = async (id, currentStatus) => {
    // console.log("id =>",id)
    const requestDoc = doc(db, "support", id);
    await updateDoc(requestDoc, { isResolved: !currentStatus });
    setRequests((prevRequests) =>
      prevRequests.map((request) =>
        request.id === id ? { ...request, isResolved: !currentStatus } : request
      )
    );
  };

  const handleResponseChange = (id, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: value,
    }));
  };

  const addResponse = async (id) => {
    const newResponse = {
      text: responses[id],
      responderId: auth.currentUser.uid,
      respondedAt: Timestamp.now(),
    };
    const requestDoc = doc(db, "support", id);
    const request = requests.find((req) => req.id === id);

    const updatedResponses = request.responses
      ? [newResponse, ...request.responses]
      : [newResponse];
    await updateDoc(requestDoc, { responses: updatedResponses });

    setRequests((prevRequests) =>
      prevRequests.map((req) =>
        req.id === id ? { ...req, responses: updatedResponses } : req
      )
    );
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: "",
    }));
  };


  const fetchUser = async (userId) => {
    const userDoc = doc(db, "users", userId);
    const userSnapshot = await getDoc(userDoc);
    if (userSnapshot.exists()) {
      return userSnapshot.data();
    } else {
      console.error("User not found: ", userId);
      return null;
    }
  };

  const handleAccordionChange = async (id) => {
    if (!users[id]) {
      const user = await fetchUser(id);
      if (user) {
        setUsers((prevUsers) => ({
          ...prevUsers,
          [id]: user,
        }));
      }
    }
  };


  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        ユーザーからのお問い合わせ
      </Typography>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="問い合わせの状態"
      >
        <Tab label="未解決" />
        <Tab label="解決" />
      </Tabs>
      {tabValue === 0 && (
        <UnresolvedRequestsList
          users={users}
          handleAccordionChange={handleAccordionChange}
        />

      )}
      {tabValue === 1 && (
        <ResolvedRequestsList
          users={users}
          handleAccordionChange={handleAccordionChange}
        />

      )}

    </Box>
  );
};

export default ContactRequests;
