import React, { useState, useEffect } from "react";
import { collection, getDoc, getDocs, updateDoc, doc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UpdateUserPrices = () => {
  const [user, setUser] = useState(null);
  const [userCount, setUserCount] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [noPriceCount, setNoPriceCount] = useState(0);
  const [priceDistributionWithPhase2, setPriceDistributionWithPhase2] = useState({});
  const [priceDistributionWithoutPhase2, setPriceDistributionWithoutPhase2] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        if (userDoc.exists()) {
          setUser(userDoc.data());
        }
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    if (user && !user?.isAdmin) {
      navigate("/home");
    }
  }, [user, navigate]);

  // ユーザー数と価格情報を取得する関数
  const fetchUserData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      setUserCount(querySnapshot.size);
      
      let noPrice = 0;
      let priceCountsWithPhase2 = {};
      let priceCountsWithoutPhase2 = {};

      querySnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const price = data.secondPhasePrice;
        const payments = data.payments || [];
        const hasPaymentPhase2 = payments.some(payment => payment.paymentPhase === "2");

        if (!price) {
          noPrice++;
        } else {
          if (hasPaymentPhase2) {
            priceCountsWithPhase2[price] = (priceCountsWithPhase2[price] || 0) + 1;
          } else {
            priceCountsWithoutPhase2[price] = (priceCountsWithoutPhase2[price] || 0) + 1;
          }
        }
      });
      
      setNoPriceCount(noPrice);
      setPriceDistributionWithPhase2(priceCountsWithPhase2);
      setPriceDistributionWithoutPhase2(priceCountsWithoutPhase2);
    } catch (error) {
      console.error("ユーザーデータの取得に失敗しました", error);
    }
  };

  // 全てのユーザーのsecondPhasePriceを¥55,000に更新する関数
  const updateAllUsersPrice = async () => {
    setUpdating(true);
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      for (const userDoc of querySnapshot.docs) {
        await updateDoc(doc(db, "users", userDoc.id), { secondPhasePrice: "¥55,000" });
      }
      alert("全てのユーザーのsecondPhasePriceを更新しました。");
    } catch (error) {
      console.error("更新に失敗しました", error);
    }
    setUpdating(false);
  };

  return (
    <div style={{ padding: 20 }}>
      <Typography variant="h5">ユーザー管理</Typography>
      <Button variant="contained" color="primary" onClick={updateAllUsersPrice} disabled={updating}>
        すべてを¥55,000にする
      </Button>
      <Button variant="outlined" color="secondary" onClick={fetchUserData} style={{ marginLeft: 10 }}>
        ユーザー数と価格情報を取得
      </Button>
      {userCount !== null && (
        <Typography variant="body1" style={{ marginTop: 10 }}>
          ユーザー数: {userCount}
        </Typography>
      )}
      {noPriceCount !== null && (
        <Typography variant="body1" style={{ marginTop: 10 }}>
          secondPhasePriceが無いユーザー数: {noPriceCount}
        </Typography>
      )}
      {Object.keys(priceDistributionWithPhase2).length > 0 && (
        <div style={{ marginTop: 10 }}>
          <Typography variant="body1">paymentPhase2があるユーザーの価格ごとの数:</Typography>
          {Object.entries(priceDistributionWithPhase2).map(([price, count]) => (
            <Typography key={price} variant="body2">
              {price}: {count}人
            </Typography>
          ))}
        </div>
      )}
      {Object.keys(priceDistributionWithoutPhase2).length > 0 && (
        <div style={{ marginTop: 10 }}>
          <Typography variant="body1">paymentPhase2が無いユーザーの価格ごとの数:</Typography>
          {Object.entries(priceDistributionWithoutPhase2).map(([price, count]) => (
            <Typography key={price} variant="body2">
              {price}: {count}人
            </Typography>
          ))}
        </div>
      )}
    </div>
  );
};

export default UpdateUserPrices;
