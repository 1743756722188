import React, { useState, useEffect } from 'react';
import { db, auth } from "../api/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Container, Box, Typography, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


const municipalities = [
    "札幌市", "江別市", "千歳市", "恵庭市", "北広島市", "石狩市", "当別町", "新篠津村", "岩見沢市", "夕張市",
    "美唄市", "芦別市", "赤平市", "三笠市", "滝川市", "砂川市", "歌志内市", "深川市", "南幌町", "奈井江町",
    "上砂川町", "由仁町", "長沼町", "栗山町", "月形町", "浦臼町", "新十津川町", "妹背牛町", "秩父別町", "雨竜町",
    "北竜町", "沼田町", "小樽市", "倶知安町", "寿都町", "黒松内町", "蘭越町", "ニセコ町", "喜茂別町", "京極町",
    "共和町", "岩内町", "積丹町", "古平町", "仁木町", "余市町", "島牧村", "真狩村", "留寿都村", "泊村",
    "神恵内村", "赤井川村", "室蘭市", "苫小牧市", "登別市", "伊達市", "豊浦町", "洞爺湖町", "壮瞥町", "白老町",
    "厚真町", "安平町", "むかわ町", "浦河町", "日高町", "平取町", "新冠町", "様似町", "えりも町", "新ひだか町",
    "函館市", "北斗市", "松前町", "福島町", "知内町", "木古内町", "七飯町", "鹿部町", "森町", "八雲町",
    "長万部町", "江差町", "上ノ国町", "厚沢部町", "乙部町", "奥尻町", "今金町", "せたな町", "旭川市", "富良野市",
    "士別市", "名寄市", "鷹栖町", "東神楽町", "当麻町", "比布町", "愛別町", "上川町", "東川町", "美瑛町",
    "上富良野町", "中富良野町", "南富良野町", "幌加内町", "和寒町", "剣淵町", "下川町", "美深町", "中川町", "占冠村",
    "音威子府村", "留萌市", "増毛町", "小平町", "苫前町", "羽幌町", "遠別町", "天塩町", "初山別村", "稚内市",
    "浜頓別町", "中頓別町", "枝幸町", "豊富町", "幌延町", "礼文町", "利尻町", "利尻富士町", "猿払村", "網走市",
    "北見市", "紋別市", "美幌町", "津別町", "大空町", "斜里町", "清里町", "小清水町", "訓子府町", "置戸町",
    "佐呂間町", "遠軽町", "湧別町", "滝上町", "興部町", "雄武町", "西興部村", "帯広市", "音更町", "士幌町",
    "上士幌町", "鹿追町", "新得町", "清水町", "芽室町", "大樹町", "広尾町", "幕別町", "池田町", "豊頃町",
    "本別町", "足寄町", "陸別町", "浦幌町", "中札内村", "更別村", "釧路市", "釧路町", "厚岸町", "浜中町",
    "標茶町", "弟子屈町", "白糠町", "鶴居村", "根室市", "別海町", "中標津町", "標津町", "羅臼町"
];

const HokkaidoMunicipalitySelect = () => {
    const [prefecture, setPrefecture] = useState("");
    const [municipality, setMunicipality] = useState("");
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            const userDocRef = doc(db, "users", auth.currentUser.uid);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setPrefecture(userData.prefecture);
                setMunicipality(userData.municipality || "");
            }
            setLoading(false);
        };

        fetchUserData();
    }, []);

    const handleMunicipalityChange = (event) => {
        setMunicipality(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const userDocRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(userDocRef, { municipality });
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    if (loading) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (prefecture !== "北海道") {
        return null;
    }

    return (
        <Container maxWidth="sm">
            <Box mt={4} border={1} borderColor="grey.500" p={2} style={{ width: '100%' }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    北海道にお住まいの方の市町村選択
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    現在の市町村: {municipality}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel>市町村</InputLabel>
                        <Select
                            value={municipality}
                            onChange={handleMunicipalityChange}
                            label="市町村"
                        >
                            <MenuItem value="">
                                <em>選択してください</em>
                            </MenuItem>
                            {municipalities.map((muni) => (
                                <MenuItem key={muni} value={muni}>{muni}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box mt={2}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            保存
                        </Button>
                    </Box>
                </form>
            </Box>
            <Dialog open={dialogOpen} onClose={handleClose}>
                <DialogTitle>登録完了</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        市町村情報が保存されました。
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        閉じる
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default HokkaidoMunicipalitySelect;

// 英語名と日本語名のマッピング
const prefectureMapping = {
    "Hokkaido": "北海道",
    "Aomori": "青森県",
    "Iwate": "岩手県",
    "Miyagi": "宮城県",
    "Akita": "秋田県",
    "Yamagata": "山形県",
    "Fukushima": "福島県",
    "Ibaraki": "茨城県",
    "Tochigi": "栃木県",
    "Gunma": "群馬県",
    "Saitama": "埼玉県",
    "Chiba": "千葉県",
    "Tokyo": "東京都",
    "Kanagawa": "神奈川県",
    "Niigata": "新潟県",
    "Toyama": "富山県",
    "Ishikawa": "石川県",
    "Fukui": "福井県",
    "Yamanashi": "山梨県",
    "Nagano": "長野県",
    "Gifu": "岐阜県",
    "Shizuoka": "静岡県",
    "Aichi": "愛知県",
    "Mie": "三重県",
    "Shiga": "滋賀県",
    "Kyoto": "京都府",
    "Osaka": "大阪府",
    "Hyogo": "兵庫県",
    "Nara": "奈良県",
    "Wakayama": "和歌山県",
    "Tottori": "鳥取県",
    "Shimane": "島根県",
    "Okayama": "岡山県",
    "Hiroshima": "広島県",
    "Yamaguchi": "山口県",
    "Tokushima": "徳島県",
    "Kagawa": "香川県",
    "Ehime": "愛媛県",
    "Kochi": "高知県",
    "Fukuoka": "福岡県",
    "Saga": "佐賀県",
    "Nagasaki": "長崎県",
    "Kumamoto": "熊本県",
    "Oita": "大分県",
    "Miyazaki": "宮崎県",
    "Kagoshima": "鹿児島県",
    "Okinawa": "沖縄県"
  };
  