import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Typography,
  Chip,
  Button,
  TextField,
  Link,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import {
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  doc,
  updateDoc,
  Timestamp,
  getDoc,
  where,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";

const UnresolvedRequestsList = ({ users, handleAccordionChange }) => {
  const [resolvedRequests, setResolvedRequests] = useState([]); // `isResolved: false` のリクエストのみ
  const [requests, setRequests] = useState([]); // 問い合わせリスト
  const [lastDoc, setLastDoc] = useState(null); // 最後に取得したドキュメント
  const [loading, setLoading] = useState(false); // ロード中の状態
  const [responses, setResponses] = useState({}); // 対応入力用
  const observer = useRef(); // Intersection Observer の参照

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingResponse, setDeletingResponse] = useState(null);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingResponse, setEditingResponse] = useState(null);
  const [editingText, setEditingText] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // 🔹 初回ロード（最初の30件を取得）
  useEffect(() => {
    const fetchInitialRequests = async () => {
      setLoading(true);
      const q = query(
        collection(db, "support"),
        orderBy("createdAt", "desc"),
        limit(30)
      );
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        const requests = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // 🔥 isResolved が false または undefined のデータのみ表示
        const unresolvedRequests = requests.filter(
          (req) => req.isResolved !== true
        );
        setResolvedRequests(unresolvedRequests);
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      }
      setLoading(false);
    };

    fetchInitialRequests();
  }, []);

  // 🔹 スクロールで次の30件を取得
  //   const fetchMoreRequests = useCallback(async () => {
  //     if (!lastDoc || loading) return;
  //     setLoading(true);

  //     const q = query(
  //       collection(db, "support"),
  //       orderBy("createdAt", "desc"),
  //       startAfter(lastDoc),
  //       limit(30)
  //     );
  //     const snapshot = await getDocs(q);

  //     if (!snapshot.empty) {
  //       const newRequests = snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));

  //       // 🔥 `isResolved: false` または `isResolved` が `undefined` のデータのみ追加
  //       const newUnresolvedRequests = newRequests.filter(
  //         (req) => req.isResolved !== true
  //       );

  //       setAllRequests((prev) => [...prev, ...newRequests]);
  //       setResolvedRequests((prev) => [...prev, ...newUnresolvedRequests]);
  //       setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
  //     }
  //     setLoading(false);
  //   }, [lastDoc, loading]);

  // 🔹 スクロールで追加データ取得
  const fetchMoreRequests = useCallback(async () => {
    if (!lastDoc || loading) return;
    setLoading(true);

    const q = query(
      collection(db, "support"),
      orderBy("createdAt", "desc"),
      startAfter(lastDoc),
      limit(100)
    );
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      const newRequests = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // 🔥 isResolved が false または undefined のデータのみ追加
      const newUnresolvedRequests = newRequests.filter(
        (req) => req.isResolved !== true
      );
      setResolvedRequests((prev) => [...prev, ...newUnresolvedRequests]);
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
    }
    setLoading(false);
  }, [lastDoc, loading]);

  // 🔹 スクロール監視のための ref を設定
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect(); // 既存の Observer を解除

      observer.current = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            fetchMoreRequests(); // 画面に 80% 表示されたら次のデータ取得
          }
        },
        { threshold: 0.8 } // 80% 表示されたら
      );

      if (node) observer.current.observe(node);
    },
    [fetchMoreRequests, loading]
  );

  // 🔹 「解決にする」ボタンを押したとき
  const handleResolve = async (id, currentStatus) => {
    try {
      const requestDoc = doc(db, "support", id);
      await updateDoc(requestDoc, { isResolved: !currentStatus });

      // 🔥 即時リスト更新（Firestoreの更新を待たずに削除）
      setResolvedRequests((prevRequests) =>
        prevRequests.filter((req) => req.id !== id)
      );

      // 🔥 成功通知
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Firestore更新エラー:", error);
    }
  };

  // 🔹 対応入力の変更を管理
  const handleResponseChange = (id, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: value,
    }));
  };

  // 🔹 Firestore に対応を追加
  const addResponse = async (id) => {
    if (!responses[id]?.trim()) return; // 空の対応は追加しない

    const newResponse = {
      text: responses[id].trim(),
      responderId: auth.currentUser.uid,
      respondedAt: Timestamp.now(),
    };

    const requestDoc = doc(db, "support", id);
    const request = resolvedRequests.find((req) => req.id === id);

    // 🔥 `responses` が `undefined` の場合は空配列にする
    const updatedResponses = request?.responses
      ? [newResponse, ...request.responses]
      : [newResponse];

    try {
      await updateDoc(requestDoc, { responses: updatedResponses });

      // 🔥 即時リスト更新（リクエストのresponsesを更新）
      setResolvedRequests((prevRequests) =>
        prevRequests.map((req) =>
          req.id === id ? { ...req, responses: updatedResponses } : req
        )
      );

      // 入力欄をクリア
      setResponses((prevResponses) => ({
        ...prevResponses,
        [id]: "",
      }));
    } catch (error) {
      console.error("対応の追加中にエラーが発生しました:", error);
    }
  };

  const handleDeleteDialogOpen = (response) => {
    console.log("削除ダイアログを開く:", response);

    if (!response || !response.requestId) {
      console.error("削除対象のレスポンスに requestId がありません:", response);
      return;
    }

    setDeletingResponse(response);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setDeletingResponse(null);
  };

  const handleDeleteConfirm = async () => {
    if (!deletingResponse || !deletingResponse.requestId) {
      console.error("削除対象のレスポンスがありません");
      return;
    }

    const requestDoc = doc(db, "support", deletingResponse.requestId);
    const request = requests.find(
      (req) => req.id === deletingResponse.requestId
    );

    if (!request || !request.responses) {
      console.error("削除対象のリクエストが見つかりません");
      return;
    }

    const updatedResponses = request.responses.filter(
      (res) => res.respondedAt !== deletingResponse.respondedAt
    );

    try {
      await updateDoc(requestDoc, { responses: updatedResponses });

      setRequests((prevRequests) =>
        prevRequests.map((req) =>
          req.id === deletingResponse.requestId
            ? { ...req, responses: updatedResponses }
            : req
        )
      );

      setDeleteDialogOpen(false);
      setDeletingResponse(null);
    } catch (error) {
      console.error("対応履歴の削除中にエラーが発生しました:", error);
    }
  };

  const handleEditDialogOpen = (response) => {
    setEditingResponse(response);
    setEditingText(response.text);
    setEditDialogOpen(true);
  };
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setEditingResponse(null);
    setEditingText("");
  };
  const handleEditSave = async () => {
    if (!editingResponse || !editingResponse.requestId) return;

    const requestDoc = doc(db, "support", editingResponse.requestId);
    const request = requests.find(
      (req) => req.id === editingResponse.requestId
    );

    const updatedResponses = request.responses.map((res) =>
      res.respondedAt.isEqual(editingResponse.respondedAt)
        ? { ...res, text: editingText }
        : res
    );

    try {
      await updateDoc(requestDoc, { responses: updatedResponses });

      setRequests((prevRequests) =>
        prevRequests.map((req) =>
          req.id === editingResponse.requestId
            ? { ...req, responses: updatedResponses }
            : req
        )
      );

      setEditDialogOpen(false);
      setEditingResponse(null);
      setEditingText("");
    } catch (error) {
      console.error("対応履歴の編集中にエラーが発生しました:", error);
    }
  };

  return (
    <Box>
      {resolvedRequests.map((request, index) => (
        <Accordion
          key={request.id}
          sx={{
            mb: 2,
            backgroundColor:
              request.responses && request.responses.length > 0
                ? "#e3f2fd" // 🔹 対応履歴がある場合は薄い青色
                : "white", // 🔹 通常の白
            transition: "background-color 0.3s",
          }}
          onChange={() => handleAccordionChange(request.userId)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ mr: 2, fontWeight: "bold" }}
              >
                {index + 1}.
              </Typography>
              <Avatar
                alt={request.name}
                src={request.avatar || users[request.userId]?.avatar}
                sx={{ mr: 1 }}
              />
              <Typography variant="body1">
                {request.name || users[request.userId]?.name}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <Typography variant="h6">{request.category}</Typography>
              <Chip label="未解決" color="secondary" sx={{ ml: 2 }} />
              <Typography variant="body2" sx={{ ml: 2 }}>
                {dayjs(request.createdAt.toDate()).fromNow()} (
                {dayjs(request.createdAt.toDate()).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
                )
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                handleResolve(request.id, request.isResolved || false);
                // toggleResolvedStatus(request.id, request.isResolved);
              }}
              sx={{ alignSelf: "flex-start", ml: "auto" }}
            >
              解決にする
            </Button>
          </AccordionSummary>

          <AccordionDetails>
            <Box sx={{ mb: 2 }}>
              {/* 🔹 メッセージ内容の表示 */}
              <Typography
                variant="body2"
                color="textPrimary"
                sx={{
                  mt: 1,
                  p: 1,
                  backgroundColor: "#f5f5f5",
                  borderRadius: "4px",
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                <strong>メッセージ:</strong> {request.message || "該当なし"}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <strong>メール:</strong>{" "}
                {request.email ? (
                  <Link href={`mailto:${request.email}`} underline="hover">
                    {request.email}
                  </Link>
                ) : (
                  "該当なし"
                )}
              </Typography>

              <Typography variant="body2" color="textSecondary">
                <strong>ログインメール:</strong>{" "}
                {request.loginEmail ? (
                  <Link href={`mailto:${request.loginEmail}`} underline="hover">
                    {request.loginEmail}
                  </Link>
                ) : (
                  "該当なし"
                )}
              </Typography>

              <Typography variant="body2" color="textSecondary">
                <strong>返信希望メール:</strong>{" "}
                {request.replyEmail ? (
                  <Link href={`mailto:${request.replyEmail}`} underline="hover">
                    {request.replyEmail}
                  </Link>
                ) : (
                  "該当なし"
                )}
              </Typography>

              <Typography variant="body2" color="textSecondary">
                <strong>ユーザID:</strong> {request.userId || "該当なし"}
              </Typography>

              {/* 🔹 支払い情報の表示 */}
              {users[request.userId]?.payments ? (
                <Box
                  sx={{
                    mt: 2,
                    p: 1,
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                  }}
                >
                  <Typography variant="h6">💳 支払い情報</Typography>
                  {users[request.userId].payments.map((payment, index) => (
                    <Box key={index} sx={{ mb: 1 }}>
                      <Typography variant="body2">
                        <strong>支払いフェーズ:</strong>{" "}
                        {payment.paymentPhase || "不明"}
                      </Typography>
                      <Typography variant="body2">
                        <strong>支払い方法:</strong>{" "}
                        {payment.paymentMethod || "不明"}
                      </Typography>
                      <Typography variant="body2">
                        <strong>支払額:</strong>{" "}
                        {payment.amountTotal
                          ? `${payment.amountTotal} 円`
                          : "不明"}
                      </Typography>
                      <Typography variant="body2">
                        <strong>支払い日:</strong>{" "}
                        {payment.timestamp
                          ? dayjs(payment.timestamp.toDate()).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          : "不明"}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Typography
                  variant="body2"
                  sx={{ mt: 2 }}
                  color="textSecondary"
                >
                  💳 支払い情報なし
                </Typography>
              )}
            </Box>
            <TextField
              fullWidth
              label="対応を入力"
              variant="outlined"
              value={responses[request.id] || ""}
              onChange={(e) => handleResponseChange(request.id, e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => addResponse(request.id)}
              disabled={
                !responses[request.id] || responses[request.id].trim() === ""
              }
            >
              対応を追加
            </Button>

            {/* 🔹 対応履歴の表示 */}
            {request.responses && request.responses.length > 0 ? (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6">対応履歴</Typography>
                {request.responses.map((response, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <Avatar
                        alt="Responder"
                        src={users[response.responderId]?.avatar}
                        sx={{ width: 24, height: 24, mr: 1 }}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {response.respondedAt instanceof Timestamp
                          ? dayjs(response.respondedAt.toDate()).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          : dayjs(response.respondedAt).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                      {response.text}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography variant="body2" color="textSecondary">
                対応履歴はありません。
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress />
        </Box>
      )}
      <div ref={lastElementRef} />

      {/* 🔹 解決通知の Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="解決済みにしました"
      />

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">対応履歴の消去</DialogTitle>
        <DialogContent>
          <DialogContentText>
            本当に対応履歴を消去してもよろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            消去
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        aria-labelledby="edit-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="edit-dialog-title">対応履歴の編集</DialogTitle>
        <DialogContent>
          <DialogContentText>編集内容を入力してください。</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="対応内容"
            type="text"
            fullWidth
            value={editingText}
            onChange={(e) => setEditingText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleEditSave} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UnresolvedRequestsList;
