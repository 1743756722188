import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { db, auth } from "../api/firebase";
import { collection, addDoc, serverTimestamp, doc, getDoc } from "firebase/firestore";

const deadline = new Date("2025-02-22T23:59:59");

const ManmaruMarcheSubmission = () => {
  const [open, setOpen] = useState(false);
  const [catchphrase, setCatchphrase] = useState("");
  const [concept, setConcept] = useState("");
  const [loading, setLoading] = useState(false);
  const [isBeforeDeadline, setIsBeforeDeadline] = useState(true);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const updateDeadlineStatus = () => {
      const now = new Date();
      setIsBeforeDeadline(now <= deadline);
    };
    updateDeadlineStatus();
    const interval = setInterval(updateDeadlineStatus, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchUserName = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        if (userDoc.exists()) {
          setUserName(userDoc.data().name || "");
        }
      }
    };
    fetchUserName();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCatchphrase("");
    setConcept("");
  };

  const handleSubmit = async () => {
    if (!catchphrase.trim() || !concept.trim()) return;
    if (!auth.currentUser) {
      alert("ログインが必要です");
      return;
    }

    setLoading(true);
    try {
      await addDoc(collection(db, "manmaruMarcheIdeas"), {
        userId: auth.currentUser.uid,
        userName: userName,
        createdAt: serverTimestamp(),
        catchphrase,
        concept,
      });
      handleClose();
    } catch (error) {
      console.error("エラー: アイデアの保存に失敗しました", error);
      alert("送信に失敗しました");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        disabled={!isBeforeDeadline}
      >
        キャッチコピー & コンセプトを投稿
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>まんまるマルシェのアイデア投稿</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary" paragraph>
            ※注意: 提出されたアイデアの帰属権はマスターマインドメンバーズに帰属します。
          </Typography>
          <Typography variant="body2" paragraph>
            例えば、「ぐるり」という企画をご存知ですか？
            そこでは、集まったみんなが不要になったものを持ち寄って物々交換するんです。「まんまるマルシェ」も、
            名前を聞いたら「ああ、あんな感じね」とすぐに思い浮かぶような
            ものにしたいと考えています。あなたのアイデアをぜひ応募してください。
          </Typography>
          <Typography variant="body2" color="error" paragraph>
            募集期限: 2025年2月22日 23:59:59 まで
          </Typography>
          <TextField
            margin="dense"
            label="キャッチコピー"
            fullWidth
            value={catchphrase}
            onChange={(e) => setCatchphrase(e.target.value)}
          />
          <TextField
            margin="dense"
            label="コンセプト"
            fullWidth
            multiline
            rows={3}
            value={concept}
            onChange={(e) => setConcept(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">キャンセル</Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={loading || !catchphrase.trim() || !concept.trim() || !isBeforeDeadline}
          >
            送信
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManmaruMarcheSubmission;
